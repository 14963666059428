import axios, { InternalAxiosRequestConfig } from "axios";

const doesContainsForbiddenPatterns = (input: string): boolean => {
  const forbiddenPatterns = [
    /select\s+.*\s+from\s+/i,
    /insert\s+into\s+.*\s+values\s+/i,
    /update\s+.*\s+set\s+/i,
    /delete\s+from\s+/i,
    /docker\s+(?:run|exec)\s+/i,
    /bash\s+-c\s+/i,
    /<\/script>/i,
    /javascript:\s*/i,
    /<script.*?>.*?<\/script>/i,
    // @ts-ignore
    /<?php.*?>/is,
    /system\s*\(/i,
    /exec\s*\(/i,
    /powershell\s+-Command\s+[^\s]+/i,
  ];

  return forbiddenPatterns.some((pattern) => pattern.test(input));
};

const isAllowedCharacters = (input: string): boolean => {
  const allowedPattern = /^[a-zA-Z0-9âäàáéèêëíìîïóòôöúüùûÿçñÑ@ŒœÄÅÂÁÆÉÈËÏÎÔÖÙÛÜÚŸßÍÓÒ()ãõÇÊÃÕÌÀÇ¡¿.,:\[\]"'`~°&\-_\+\/ ]*$/;
  return allowedPattern.test(input);
};

const verifyObjectEntryValue = (entryValue: any) => {
  if (entryValue)
    if (typeof entryValue === "string") {
      if (!isValidInput(entryValue)) {
        console.error('Invalid entry value ', entryValue)
        throw new axios.Cancel(`Malicious payload`);
      }
    } else
      Object.keys(entryValue).map((key) => {
        if (!["fields", "operator"].includes(key))
          verifyObjectEntryValue(entryValue[key]);
      });
};

export const checkUserPayload = (config: InternalAxiosRequestConfig) => {
  if (config.url !== "/api/tasks/v3/journeys") {
    const payload = config.data;
    verifyObjectEntryValue(payload);
  }
};

export const isValidInput = (input: string): boolean => {
  return isAllowedCharacters(input) && !doesContainsForbiddenPatterns(input);
};

export const isValidPassword = (password: string): boolean => {
  return !doesContainsForbiddenPatterns(password);
};
